module.exports = [
	{
		type: 'image',
		id: 'bg-img', //默认允许编辑
		url: 'https://doc.efreight.cn/weapp/zjhy/static/2021-12-28/poster-4-new.png',
		css: {
			top: 0,
			left: 0,
			width: 360,
			height: 640,
		},
    },
    {
		type: 'image',
		id: 'avatar',
		url: 'https://doc.efreight.cn/test/2021-12-28/avatar-new.jpg',
		css: {
			top: 27,
			left: 27,
			width: 40,
			height: 40,
			borderRadius: true,
		},
	},
	{
		type: 'text',
		id: 'title',
		text: '店铺名称',
		css: {
			top: 36,
			left: 73,
			width: 240,
            color:'#FFFFFF',
            fontSize: 16,
            fontWeight: 'normal',
		},
		editAble: true
	},
    {
		type: 'text',
		text: '优惠价格不定期更新',
		css: {
			top: 200,
            left: 180,
            width:300,
            color:'#FFFFFF',
            fontSize: 16,
            fontWeight: 'normal',
            originX:'center',
        },
        editAble: true
    },
	{
		id: 'qrcode',
		type: 'qrcode',
		content: '二维码',
		css: {
			top: 512,
			left: 240,
			width: 80,
			height: 80,
            color:'#000000',
            light:'',
		}
	},
	{
		type: 'text',
		text: '扫码进店享优惠',
		css: {
			top: 600,
            left: 237,
            color:'#FFFFFF',
            fontSize: 12,
            fontWeight: 'normal',
		}
	}
]