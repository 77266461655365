module.exports = [
	{
		type: 'image',
		id: 'bg-img', //默认允许编辑
		url: 'https://doc.efreight.cn/weapp/zjhy/static/2021-12-28/shop-poster3-1-new.png',
		css: {
			top: 0,
			left: 0,
			width: 360,
			height: 640,
		},
	},
	{
		type: 'image',
		url: 'https://doc.efreight.cn/weapp/zjhy/static/2021-12-28/shop-poster3-2-new.png',
		css: {
			top: 540,
			left: 0,
			width: 360,
			height: 101,
		},
    },
    {
		type: 'text',
		text: '最新低价航线',
		css: {
			top: 120,
            left: 180,
            width:300,
            color:'#ffffff',
            fontSize: 38,
            fontWeight: 'normal',
            originX:'center',
		}
	},
	{
		type: 'image',
		id: 'avatar',
		url: 'https://doc.efreight.cn/test/2021-12-28/avatar-new.jpg',
		css: {
			top: 570,
			left: 20,
			width: 50,
			height: 50,
			borderRadius: true,
		},
	},
    {
		type: 'text',
		text: '优惠价格不定期更新',
		css: {
			top: 180,
            left: 180,
            width:300,
            color:'#FFD477',
            fontSize: 18,
            fontWeight: 'normal',
            originX:'center',
        },
        editAble: true
	},
	
	{
		type: 'text',
		id: 'title',
		text: '店铺名称',
		css: {
			top: 575,
			left: 80,
			width: 240,
            color:'#666666',
            fontSize: 16,
            fontWeight: 'normal',
            overflow: 'hidden',
            textOverflow:'ellipsis',
            whiteSpace: 'nowrap',
            // backgroundColor:'#ffffff',
		},
		editAble: true
	},
	{
		type: 'text',
		text: '扫码进店享优惠',
		css: {
			top: 602,
            left: 80,
            color:'#666666',
            fontSize: 12,
            fontWeight: 'normal',
		}
	},
	{
		id: 'qrcode',
		type: 'qrcode',
		content: '二维码',
		css: {
			top: 550,
			left: 266,
			width: 80,
			height: 80,
            color:'#000000',
            light:'#0000',
		}
	},
]