module.exports = [
	{
		type: 'image',
		id: 'bg-img', //默认允许编辑
		url: 'https://doc.efreight.cn/weapp/zjhy/static/2021-12-28/poster-1-new.png',
		css: {
			top: 0,
			left: 0,
			width: 360,
			height: 640,
		},
	},
	{
		type: 'image',
		id: 'avatar',
		url: 'https://doc.efreight.cn/test/2021-12-28/avatar-new.jpg',
		css: {
			top: 50,
			left: 150,
			width: 55,
			height: 55,
			borderRadius: true,
		},
	},
	{
		type: 'text',
		id: 'title',
		text: '店铺名称',
		css: {
			top: 120,
			left: 180,
			color:'#fff',
			fontSize: 16,
			originX:'center',
			
		},
		editAble: true
	},
	{
		type: 'text',
		id: 'shop-title',
		text: '店铺介绍',
		css: {
			top: 530,
			left: 180,
			color:'#fff',
			fontSize: 16,
			originX:'center',
		},
		editAble: true
	},
	{
		id: 'qrcode',
		type: 'qrcode',
		content: '二维码',
		css: {
			top: 342,
			left: 120,
			width: 120,
			height: 120,
			color:'#fff',
			light:'#0000',
		}
	},
	{
		type: 'text',
		text: '微信扫一扫',
		css: {
			top: 486,
			left: 140,
			color:'#fff',
			fontSize: 16,
		}
	},
]