module.exports = [
	{
		type: 'image',
		id: 'bg-img', //默认允许编辑
		url: 'https://doc.efreight.cn/weapp/zjhy/static/2021-12-28/poster-2-new-retry.png',
		css: {
			top: 0,
			left: 0,
			width: 360,
			height: 640,
		},
	},
	{
        type: 'block',
		css: {
			top: 538,
			left: 0,
			width: 360,
			height: 102,
			backgroundColor:'rgba(161, 213, 226, 1)',
        },
	},
	{
		type: 'image',
		id: 'avatar',
		url: 'https://doc.efreight.cn/test/2021-12-28/avatar-new.jpg',
		css: {
			top: 300,
			left: 154,
			width: 55,
			height: 55,
			borderRadius: true,
		},
		
	},
	{
		type: 'text',
		id: 'title',
		text: '店铺名称',
		css: {
			top: 561,
			left: 30,
			width: 240,
            color:'#2185A0',
            fontSize: 20,
            fontWeight: 500,
            overflow: 'hidden',
            textOverflow:'ellipsis',
            whiteSpace: 'nowrap',
		},
		editAble: true
	},
	{
		type: 'text',
		text: '长按扫描二维码',
		css: {
			top: 598,
            left: 30,
            color:'#2185A0',
            fontSize: 12,
            fontWeight: 'normal',
		}
	},
	{
		id: 'qrcode',
		type: 'qrcode',
		content: '二维码',
		css: {
			top: 552,
			left: 266,
			width: 74,
			height: 74,
            color:'#000',
		}
	},
]