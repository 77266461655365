//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

/* eslint-disable */
import {fabric} from 'fabric';
import QRCode from 'qrcode';
import wx from 'weixin-js-sdk';
import axios from 'axios';
import POSTER from '@/assets/json/shop/index';
import LOGGER from '@/apis/log';
import edit2PNG from '@/assets/images/edit_30.png';
import close2PNG from '@/assets/images/delete_30.png';
import scalePNG from '@/assets/images/scale_30.png';
import textClonePNG from '@/assets/images/clone_30.png';

const DEFAULT_APPID = 'wx60ced1c8de7908d5'
const eidtIconImg = document.createElement('img');
eidtIconImg.src = edit2PNG;//editIcon64; 
const scaleIconImg = document.createElement('img');
scaleIconImg.src = scalePNG;
const textCloseIconImg = document.createElement('img');
textCloseIconImg.src = close2PNG; //textCloseIcon64;
const textCloneIconImg = document.createElement('img');
textCloneIconImg.src = textClonePNG;

const rate = window.screen.height / window.screen.width;    
let limit =  window.screen.height == window.screen.availHeight ? 1.8 : 1.65;
const FULL_SCREEN = rate > limit

const SAFE_AREA_DIFF = FULL_SCREEN ? 64 : 44;
const AVAILD_WIDTH = window.screen.availWidth;
const AVAILD_HEIGHT = window.screen.availHeight - 88;  //真机下availdheight会包括顶部状态栏和导航栏高度
const SCALE_RATIO = AVAILD_WIDTH > 375 && AVAILD_HEIGHT > (750 - 88) ? 1: 0.75;  //老的plus型号，宽度够但是高度不够
const POSTER_WIDTH = 360 * SCALE_RATIO;
const POSTER_HEIGHT = 640 * SCALE_RATIO;
const MARGIN_LEFT = AVAILD_WIDTH / 2 - POSTER_WIDTH / 2;
const MARGIN_TOP = AVAILD_HEIGHT / 2 - POSTER_HEIGHT / 2 - SAFE_AREA_DIFF / 2;
const CANVAS_WIDTH = AVAILD_WIDTH;
const CANVAS_HEIGHT = !FULL_SCREEN ? (AVAILD_HEIGHT): (AVAILD_HEIGHT - 20);

const ICON_SIZE = 20;


//修复真机上canvas不能滑动的BUG https://github.com/fabricjs/fabric.js/pull/5904
(function() {
	var addListener = fabric.util.addListener,
			removeListener = fabric.util.removeListener,
			addEventOptions = { passive: false };

	fabric.util.object.extend(fabric.Canvas.prototype, /** @lends fabric.Canvas.prototype */ {
	_onTouchStart: function(e) {
		var targetR = this.findTarget(e);
		!this.allowTouchScrolling && e.preventDefault && e.preventDefault();
		targetR && e.preventDefault && e.preventDefault();
		if (this.mainTouchId === null) {
			this.mainTouchId = this.getPointerId(e);
		}
		this.__onMouseDown(e);
		this._resetTransformEventData();
		var canvasElement = this.upperCanvasEl,
		eventTypePrefix = this._getEventPrefix();
		addListener(fabric.document, 'touchend', this._onTouchEnd, addEventOptions);
		addListener(fabric.document, 'touchmove', this._onMouseMove, addEventOptions);
		// Unbind mousedown to prevent double triggers from touch devices
		removeListener(canvasElement, eventTypePrefix + 'down', this._onMouseDown);
	}
	});
})();

import FontFaceObserver from 'fontfaceobserver';
export default {
	metaInfo () {
      	return {
			title: '海报制作',
		}
	},
	data: ()=> ({
		canvas: null,
		localImgUrl: null,
		width: 0,
		height: 0,
		showTextEditModel: false,
		textEditModelContent: null,
		showImgMenu: false,
		showTextMenu: false,
		showColorMenu: false,
		id: null,
		bgObj: null,
		fontList: [
			{ name: '默认', color: '#ee0a24', chosen: true },
        	// { name: '禁用选项', disabled: true },
			{ name: '优设标题黑', loading: true,},
        	{ name: '夏行楷', loading: true,},
			{ name: '联盟起艺卢帅正锐黑体', loading: true},
			{ name: 'Klipan', loading: true},
			{ name: 'Facon', loading: true},

		],
		showFontChooseSheet: false,
	}),
	props: {
		jsonArray: Array,
		appId: String,
		posterKey: String,
	},
	created: async function() {
		this.id = new Date().getTime()
		this.height = CANVAS_HEIGHT,
		this.width = CANVAS_WIDTH
	},
	mounted: async function() {
		this.canvas = new fabric.Canvas('canvas', {allowTouchScrolling: true}) //必须在mounted之后创建
		this.canvas.backgroundColor = '#fff'
		fabric.Object.prototype.objectCaching = false
		this.canvas.preserveObjectStacking = true  //https://github.com/fabricjs/fabric.js/issues/6696 低层对象被选中时不会在顶部显示
		this.canvas.controlsAboveOverlay = true  //https://github.com/Rookie-Birds/Fabric-Tutorial_zh-CN/blob/master/part-8.md
		{
			let clipPath2 = new fabric.Rect({
				top: MARGIN_TOP,
				left: MARGIN_LEFT,
				width: POSTER_WIDTH, //40 * 9  => 30 * 9
				height: POSTER_HEIGHT,  //40 * 16 => 30 * 16
			});
			this.canvas.clipPath = clipPath2
		}

		let _this = this
		this.$watch("jsonArray", async function(val,oldval){
			await _this.setupJSON(val)
			_this.canvas.on('selection:cleared', this.selectedHandle)
		})
		let ua = navigator.userAgent.toLowerCase();
		_this.weAppEnv= ua.match(/MicroMessenger/i)=="micromessenger"
		_this.initWxConfig()
		this.setupEditBox()
		window.scrollTo(0,0)
		this.$nextTick(()=> {
			this.$refs.main.scrollTo(0, 0)
		})


		
	},
	methods: {
		setupJSON: async function(json) {
			await this.initBgImg()
			let _this = this
			for(let i = 0; i < json.length; i++) {
				let obj = json[i],
					zIndex = i+2
				if (obj.type == 'text') {
					await _this.textHandler(obj, zIndex*2)
				}
				if (obj.type == 'image') {
					await _this.imageHandler(obj, zIndex-1)
				}
				if (obj.type == 'qrcode') {
					await _this.qrcodeHandler(obj, zIndex)
				}
				if (obj.type == 'block') {
					await _this.blockHandler(obj, zIndex)
				}
				if (obj.type == 'line') {
					await _this.lineHandler(obj, zIndex)
				}
				if (obj.type == 'circle') {
					await _this.circleHandler(obj, zIndex)
				}
			}
		},
		initBgImg: function(){
			return new Promise((resolve, reject)=> {
				var rect = new fabric.Rect({
					left: MARGIN_LEFT,
					top: MARGIN_TOP,
					fill: 'white',
					width: POSTER_WIDTH,
					height: POSTER_HEIGHT
				});
				this.canvas.add(rect)
				rect.sendToBack();
				rect.set('selectable', false);
				resolve()
			})
		},
		textHandler: function(obj,zIndex){
			let _this = this
			return new Promise((resolve, reject)=> {
				let text = new fabric.Text(
					obj.text,
					{ 
						left: obj.css.left * SCALE_RATIO + MARGIN_LEFT, 
						top: obj.css.top * SCALE_RATIO + MARGIN_TOP,
						fontFamily: obj.css.fontFamily || 'PingFang SC', //'Comic Sans' ,
						fontSize: (obj.css.fontSize || 20) * SCALE_RATIO,
						fontWeight: obj.css.fontWeight,
						fill:obj.css.color,
						originX: obj.css.originX ||'left',
						backgroundColor:obj.css.backgroundColor,
						width: obj.css.width * SCALE_RATIO,
						breakWords: obj.css.breakWords,
						textAlign: obj.css.textAlign ||'left',
						padding: 10,
						stroke: obj.css.stroke|| '',
						shadow: obj.css.shadow|| '',
					})
				text.on('selected', _this.selectedHandle)
				text.set('selectable', !!obj.editAble);
				_this.canvas.add(text);
				text.moveTo(zIndex)
				text.bringForward();
				resolve()
			})
		},
		imageHandler: function(obj,zIndex) {
			let _this = this
			return new Promise((resolve, reject)=> {
				new fabric.Image.fromURL(obj.url, function(img) {
					let scaleRatio = obj.css.width  * SCALE_RATIO / img.width 
					img.scale(scaleRatio); 
					img.on('selected', _this.selectedHandle)
					img.top = obj.css.top  * SCALE_RATIO + MARGIN_TOP
					img.left = obj.css.left * SCALE_RATIO + MARGIN_LEFT
					img.originX = obj.css.originX || 'left'
					//不设置height,避免选择框不能匹配图像边缘
					img.on('selected', _this.selectedHandle)
					img.set('selectable', !!obj.editAble);
					img.set('dirty', true);
					if(obj.css.borderRadius) {						
						var clipPath = new fabric.Circle({
							radius: img.width / 2,
							originX: 'center',
							originY: 'center',
						});
						img.clipPath = clipPath
					}
					img.set('originObj', obj)
					_this.canvas.add(img);
					img.moveTo(zIndex)
					if(obj.id == 'bg-img') {
						_this.bgObj = img
						img.set('selectable', true);
					}
				}, {crossOrigin: 'anonymous'});
				resolve()
			})
		},
		qrcodeHandler: function(obj,zIndex){
			let _this = this
			return new Promise(async (resolve, reject)=> {
				let qrcode = await QRCode.toDataURL(obj.content, {color: {dark: obj.css.color, light: obj.css.light},margin:3})
				new fabric.Image.fromURL(qrcode, function(img) {
					let scaleRatio = obj.css.width * SCALE_RATIO / img.width 
					img.scale(scaleRatio);
					img.top = obj.css.top * SCALE_RATIO + MARGIN_TOP
					img.left = obj.css.left * SCALE_RATIO + MARGIN_LEFT
					img.width = img.width
					img.height = obj.css.height / scaleRatio
					img.on('selected', _this.selectedHandle)
					img.set('selectable', !!obj.editAble);
					_this.canvas.add(img);
					img.moveTo(zIndex)
				}, {crossOrigin: 'anonymous'});
				resolve()
			})
		},
		blockHandler: function(obj,zIndex){
			let _this = this
			return new Promise(async (resolve, reject)=> {
				let square = new fabric.Rect({ 
					width:obj.css.width  * SCALE_RATIO, 
					height: obj.css.height  * SCALE_RATIO, 
					left:obj.css.left  * SCALE_RATIO + MARGIN_LEFT, 
					top: obj.css.top * SCALE_RATIO + MARGIN_TOP, 
					fill: obj.css.backgroundColor,
				});
				if(obj.radius){
					square.set('rx', obj.css.rx * SCALE_RATIO);
					square.set('ry', obj.css.ry * SCALE_RATIO);
				}
				square.set('selectable', false);
				_this.canvas.add(square);
				square.moveTo(zIndex)
				resolve()
			})
		},
		lineHandler: function(obj,zIndex){
			let _this = this
			return new Promise(async (resolve, reject)=> {
				let line = new fabric.Line([
						obj.css.left * SCALE_RATIO, 
						obj.css.top * SCALE_RATIO, 
						obj.css.width * SCALE_RATIO, 
						obj.css.top * SCALE_RATIO], {
					fill: obj.css.backgroundColor,
					stroke: obj.css.backgroundColor,
					strokeDashArray: obj.dashed ? [3,1]:[],
					strokeWidth: obj.css.height,
					selectable:!!obj.editAble? true : false,
				});
				line.on('selected', _this.selectedHandle)
				line.set('selectable', !!obj.editAble);
				_this.canvas.add(line);
				line.moveTo(zIndex)
				resolve()
			})
		},
		circleHandler: function(obj,zIndex){
			let _this = this
			return new Promise(async (resolve, reject)=> {
				let circle = new fabric.Circle({
					radius: obj.css.width/2,
					fill: '',
					stroke: obj.css.color,
					strokeWidth: 1,
					originX: 'center', 
					originY: 'center' 
				});
				let group = new fabric.Group([circle],{ 
					left: obj.css.left-1 + MARGIN_LEFT, top: obj.css.top-1 + MARGIN_TOP
				});
				group.on('selected', _this.selectedHandle)
				group.set('selectable', !!obj.editAble);
				_this.canvas.add(group);
				group.moveTo(zIndex)
				resolve()
			})
			

		},
		selectedHandle: function() {
			let cnt = this.canvas.getActiveObjects().length
			if(cnt != 1) {
				//0表示点击完成，1表示选中某个图或者文本
				this.canvas.discardActiveObject()
				this.canvas.requestRenderAll()
				return
			}
			let obj = this.canvas.getActiveObject()
			let type = this.canvas.getActiveObject() ? this.canvas.getActiveObject().type : null
			try {
				obj.borderColor = 'rgb(255,121,125)'
			} catch(err) {
				debugger
			}
			
			type && this.setupEditBox(type)
			this.canvas.requestRenderAll()
			this.showTextMenu = type == 'text'
			this.showImgMenu = type == 'image'
		},

		setupEditBox: function(targetType) {

			Object.values(fabric.Object.prototype.controls).forEach(c => c.visible = false);

			let controlsUtils = fabric.controlsUtils,
				scaleSkewStyleHandler = controlsUtils.scaleSkewCursorStyleHandler,
				scaleStyleHandler = controlsUtils.scaleCursorStyleHandler,
				scalingEqually = controlsUtils.scalingEqually,
				scalingYOrSkewingX = controlsUtils.scalingYOrSkewingX,
				scalingXOrSkewingY = controlsUtils.scalingXOrSkewingY,
				scaleOrSkewActionName = controlsUtils.scaleOrSkewActionName,
				objectControls = fabric.Object.prototype.controls;
			
			let _this = this


			fabric.Object.prototype.controls.edit = new fabric.Control({
				x: 0.5,
				y: -0.5,
				cursorStyle: 'pointer',
				mouseUpHandler: _this.showEditPopover,
				render: renderTextEditIcon,
				cornerSize: 32,
				visible: targetType == 'text'
			});

			fabric.Object.prototype.controls.scale = new fabric.Control({				
				x: 0.5,
				y: 0.5,
				cursorStyle: 'pointer',
				render: renderImageScaleIcon,
				cornerSize: 32,
				actionHandler:scalingEqually,
				visible: true
			});

			fabric.Object.prototype.controls.clone = new fabric.Control({				
				x: -0.5,
				y: -0.5,
				cursorStyle: 'pointer',
				render: renderTextCloneIcon,
				cornerSize: 32,
				mouseUpHandler: cloneObject,
				visible: targetType == 'text'
			});

			fabric.Object.prototype.controls.text_close = new fabric.Control({				
				x: -0.5,
				y: 0.5,
				cursorStyle: 'pointer',
				render: renderTextCloseIcon,
				cornerSize: 32,
				mouseUpHandler: _this.deleteTextHandle,
				visible: targetType == 'text'
			});

			function renderImageScaleIcon(ctx, left, top, styleOverride, fabricObject) {
				let w = ICON_SIZE, h = ICON_SIZE;
				ctx.save();
				ctx.translate(left, top);
				ctx.rotate(fabric.util.degreesToRadians(fabricObject.angle));
				ctx.drawImage(scaleIconImg, -w/2, -h/2, w, h);
				ctx.restore();
				console.log('draw edit box for img done')
			}

			function renderTextEditIcon(ctx, left, top, styleOverride, fabricObject) {
				let w = ICON_SIZE, h = ICON_SIZE;
				ctx.save();
				ctx.translate(left, top);
				ctx.rotate(fabric.util.degreesToRadians(fabricObject.angle));
				ctx.drawImage(eidtIconImg, -w/2, -h/2, w, h);
				ctx.restore();
				console.log('draw edit icon for text done')
			}

			function renderTextCloseIcon(ctx, left, top, styleOverride, fabricObject) {
				let w = ICON_SIZE, h = ICON_SIZE;
				ctx.save();
				ctx.translate(left, top);
				ctx.rotate(fabric.util.degreesToRadians(fabricObject.angle));
				ctx.drawImage(textCloseIconImg, -w/2, -h/2, w, h);
				ctx.restore();
				console.log('draw close icon for text done')
			}

			function renderTextCloneIcon(ctx, left, top, styleOverride, fabricObject) {
				let w = ICON_SIZE, h = ICON_SIZE;
				ctx.save();
				ctx.translate(left, top);
				ctx.rotate(fabric.util.degreesToRadians(fabricObject.angle));
				ctx.drawImage(textCloneIconImg, -w/2, -h/2, w, h);
				ctx.restore();
				console.log('draw close icon for text done')
			}

			function cloneObject(eventData, transform) {
				var target = transform.target;
				var canvas = target.canvas;
				target.clone(function(cloned) {
					cloned.left += 10;
					cloned.top += 10;
					cloned.padding = 10
					cloned.on('selected', _this.selectedHandle)
					canvas.add(cloned)
				});
			}


		},

		showEditPopover: function(eventData, target) {
			let obj = this.canvas.getActiveObject()
			if (!obj || obj.type != 'text') {
				return
			}
			this.showTextEditModel = true;
			obj.text = obj.text.replace(/\n/g,"");
			this.textEditModelContent = obj.text
		},

		cancelTextEdit: function() {
			let obj = this.canvas.getActiveObject()
			if (!obj || obj.type != 'text') {
				return
			}
			this.showTextEditModel = false
			if(obj.breakWords){
				let txt=''
				for (let g = 0; g < obj.text.length; g++) {
					txt += `${obj.text[g]}\n`;
				}
				obj.text = txt
			}else{
				obj.text = obj.text
			}
			this.textEditModelContent = null
		},

		confirmTextEdit: function() {
			let obj = this.canvas.getActiveObject()
			if (!!obj && obj.type == 'text') {
				if(obj.breakWords){
					let txt=''
					for (let g = 0; g < this.textEditModelContent.length; g++) {
						txt += `${this.textEditModelContent[g]}\n`;
					}
					obj.text = txt
				}else{
					obj.text = this.textEditModelContent
				}
				
			}
			this.canvas.requestRenderAll();
			this.showTextEditModel = false
		},

		colorMenuHandle: function() {
			this.showColorMenu = true
		},

		done: function() {
			this.showImgMenu = false
			this.showTextMenu = false
			this.canvas.discardActiveObject().requestRenderAll()
			this.selectedHandle(null)
		},

		_cripImageFromCanvas(base64Image) {
			return new Promise((resolve, reject)=> {
				let tmpCanvas = document.createElement('canvas');
				tmpCanvas.width = POSTER_WIDTH - 1
				tmpCanvas.height = POSTER_HEIGHT - 1
				let tmpctx = tmpCanvas.getContext('2d');
				tmpCanvas.id = 'tmp-canvas'
				document.body.appendChild(tmpCanvas)
				let tmpFabricCanvas = new fabric.Canvas('tmp-canvas')
				let tmpImg = document.createElement('img')
				tmpImg.src = base64Image
				tmpImg.onload = ()=> {
					var imgInstance = new fabric.Image(tmpImg, {
						left: - MARGIN_LEFT - 1,
						top: - MARGIN_TOP,
						// width: POSTER_WIDTH,
						// height: POSTER_HEIGHT
					});
					tmpFabricCanvas.add(imgInstance);
					let res = tmpFabricCanvas.toDataURL({
							format: 'jpeg',
							quality: 1.0,
							multiplier: 1
						})
					let tmpEl = document.getElementById('tmp-canvas')
					document.body.removeChild(tmpEl.parentNode)
					resolve(res)
				}
			})
		},

    

		save:  async function() {

			this._logPoster()

			this.canvas.discardActiveObject()
			let thumbImage = this.canvas.toDataURL(
				{
					format: 'jpeg', //png会在模拟器无法显示，真机未知
					quality: 1,
					multiplier: 1.5,
					left: MARGIN_LEFT + 0.5,
					top: MARGIN_TOP + 0.5,
					width: POSTER_WIDTH - 1,
					height: POSTER_HEIGHT - 1,
				}),
				cripedImage = thumbImage
			if (this.weAppEnv) {
				this.saveHandleForWeApp(cripedImage)
				return
			}
			let obj = {
				type: 'generate-img',
				img: cripedImage
			}
			window.ReactNativeWebView && window.ReactNativeWebView.postMessage(JSON.stringify(obj))
		},

		saveHandleForWeApp: function(thumbImage) {
			let imgKey = this.posterKey
			console.log('imgKey = ', imgKey)
			wx.miniProgram.postMessage({
				data: {
					baseImg: thumbImage , 
					imgKey: imgKey,
				}
			})
			let params = '?imgKey=' + imgKey + '&route=' + JSON.stringify(this.$route.params)
			// wx.miniProgram.navigateBack({delta: 1})
			let path = '/shop/painter/preview' + params
			wx.miniProgram.redirectTo({
				url: path,
			})
		},

		fontSizeIncease: function() {
			this.showColorMenu = false
			let obj = this.canvas.getActiveObject()
			if (!obj || obj.type != 'text') return
			obj.fontSize = obj.fontSize > 100 ? obj.fontSize : obj.fontSize + 2;
			this.canvas.requestRenderAll()
		},

		fontSzieDecease: function() {
			this.showColorMenu = false
			let obj = this.canvas.getActiveObject()
			if (!obj || obj.type != 'text') return
			obj.fontSize = obj.fontSize < 10 ? obj.fontSize : obj.fontSize - 2;
			this.canvas.requestRenderAll()
		},

		blodHandle: function() {
			this.showColorMenu = false
			let obj = this.canvas.getActiveObject()
			if (!obj || obj.type != 'text') return
			obj.fontWeight = obj.fontWeight == 600 || obj.fontWeight == 'bold' ? 200 : 600;
			this.canvas.requestRenderAll()
		},

		italicHandle: function() {
			this.showColorMenu = false
			let obj = this.canvas.getActiveObject()
			if (!obj || obj.type != 'text') return
			obj.fontStyle = obj.fontStyle == 'italic' ? 'normal' : 'italic';
			this.canvas.requestRenderAll()			
		},
		througnLineHandle: function() {
			this.showColorMenu = false
			let obj = this.canvas.getActiveObject()
			if (!obj || obj.type != 'text') return
			obj.set('linethrough', !obj.linethrough)
			this.canvas.requestRenderAll()
		},
		underLineHandle: function() {
			this.showColorMenu = false
			let obj = this.canvas.getActiveObject()
			if (!obj || obj.type != 'text') return
			obj.set('underline', !obj.underline)
			this.canvas.requestRenderAll()
		},

		colorPickerHandle: function(color) {
			let obj = this.canvas.getActiveObject()
			if (!obj || obj.type != 'text') return
			obj.set('fill', color)
			this.canvas.requestRenderAll()	
		},

		pickerImageHandle: function() {
			// if (!this.weAppEnv && !window.__wxjs_is_wkwebview) {
			// 	let obj = this.canvas.getActiveObject(),
			// 		_this = this,
			// 		scaleIcon64 = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADMAAAAzCAYAAAA6oTAqAAAACXBIWXMAAAsSAAALEgHS3X78AAADd0lEQVRogdWav28TMRTHv41YQEjhHwAygMTW68xJzYQEU1hYa0TZQGQtLGWoOhIEGyBdV5aUBSSWJtLtTTckGFL4BxqpomPQO9vJ5XK+2D7fD77SKWlz57xP/Oz3/Oy16XQKp2J+CwBdbdGsB+CaeD8W1xmAUXQF4Zmrr3cDw3wyvCOum4ZPnwAYAAgQhKM8ZtjDMJ9+bQagawGgEoH1EISBzcN2MMwngF0ATTubV+o0at8QygyG+V7kDsC6Y+NVGka9H4RjnZsb2s3y3jguEYS0GU0SzO/o3KwHw3zqjTd5LbMUuXIfzN9d9fhqN+MgWxWBJHWAIGSqD7N7pl4giGxhfk/1oRqGP1QnEKkXYH5q76S7GR9w/XJss9ZGMsgu9wwPhlZBq2Qt2ZjmZkGBwdCl1pMz3KKb8RzrSOsLb9wGdt4Bl6+q7wm/AR/3igSaRImsCKrJnlk5l8+0CoTk3wfuPbK2VEPNuM1zGJ6qbGo3swqE9OcXEH51TpBQR4xzXIr9v5u72Ytz4MMe8Pec//375/x9cWqK7L0XdzOt/CdT1FsPn3CIH8dlgEhFcYfD8IFvP4NdxIy+fgvYeQ9c0XBDd6KZrSV7pp2rWXItGh/VArXdwJA77T+rGsiTMF7upqoHmsGYjxcKiPKVBjsqB2qtTbfuemIFaS7/QXocIcMJgECkCJBAC5zhGrGalrlUAbGiHtKvAZiqAqDiYGAJRK5rqYYoldYDaPsVsP2Sv1qokbck6gzozgbPsoH5q5lOpZtNagGUT2MJU3zvFA80kjCDvC1pSwX01G6cxDQoHwYKIJ3FXrYETBAOShk3ENkBDXaqIfQ/LS4f7HVCm1bxlWYQFdiKBkmmOW4UVTnjQVNZ9nQmCog6IGa9RR51uAjDyzXDQmG+f55n2yoRyP5zk1Z7cl80WTezz6Cr0STaDBYwi7kZzwYO/iOYXny3Oi3R7JY2s+UTzWALRctlGE6av+xUrCayvJQNg1nceV1jmG5agpy9DVi/nTPSY9WWevbijO8f1mlCUIJAa6XJgd66tspCmSAwOtTA9xF7FWxETaIJiY/jTOnXAPiv4hWeJSzqiwiKWlm97dkZJjZ5XB0ASmoozs4YLU3yHdHiUF2HR1CsIKRcnTfzYufNTMGGIus91D3wo5L7k4Akvt8jTwSmiQLe2GllCMA/I8Zam0JO4pQAAAAASUVORK5CYII='
			// 	//'http://fabricjs.com/assets/pug_small.jpg'
			// 	//https://github.com/fabricjs/fabric.js/issues/5471
			// 	console.log(obj.type, obj.getSrc(), obj.originObj)
			// 	!!obj && obj.setSrc(scaleIcon64, function(img){
			// 		console.log(img)
    		// 		_this.canvas.requestRenderAll();
  			// 	}, null);
			// 	// this.$toast('功能开发中，尽情期待！')
			// 	return
			// }
			//需要通过RN中webview的前置script设置全局参数来区分是小程序/公众号测试/RN
			this.bgImageChangeHandle()
		},

		initWxConfig: function() {
			let url =  window.location.href, 
				appId = 'wx60ced1c8de7908d5'
			url = url.split('#')[0]  
			console.log('/api-pass/wechat/h5/sign/share' + '?app_id=' + appId + '&url=' + url)
			const promise = axios.get('/api-pass/wechat/h5/sign/share' + '?app_id=' + appId + '&url=' + url);
			promise.then(res => {
				if (res.data.code == 200) {
					const sign = res.data.data;
					this._wxConfigJSSDK(sign);
				}
			});
			promise.catch((err) => {
				console.log(err.response);
			})
		},
		_wxConfigJSSDK: function(sign){
			console.log(sign)
			console.log(parseInt(new Date().getTime() / 1000), sign.timestamp, new Date().getTime() / 1000 - sign)
			wx.config({
				debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
				appId: sign.appId + '', // 必填，公众号的唯一标识
				timestamp: parseInt(sign.timestamp), // 必填，生成签名的时间戳
				nonceStr: sign.nonceStr + '', // 必填，生成签名的随机串
				signature: sign.signature + '',// 必填，签名
				jsApiList: ['chooseImage', 'downloadImage', 'previewImage', 'uploadImage'] // 必填，需要使用的JS接口列表
			})
			wx.ready(function(){
                wx.checkJsApi({
                    jsApiList: [
                        'chooseImage',
                        'previewImage',
                        'uploadImage',
                        'downloadImage'
                    ],
                    success: function (res) {
                        if (res.checkResult.getLocation == false) {
                            console.error('你的微信版本太低，不支持微信JS接口，请升级到最新的微信版本！');
                            return;
                        }else{
							console.log('授权成功', res)
                        }
					},
					fail: err=> {
						console.log(err)
					}
                });
			});

			wx.error(function(res){
				console.error(res)
			});
		},

		_logPoster: function() {
			LOGGER.createPoster(this.$route, this.id)
		},

		bgImageChangeHandle: function() {
			let success = (data) => {
				console.log('data', data.length, data.substring(0,20))
				let _this = this,
					obj = _this.bgObj
				!!obj && obj.setSrc(data, function(){
					_this.canvas.requestRenderAll();
				}, null);
			}, fail  = err => {
				console.error(err)
			}
			this._getLocalImage().then(success, fail)
		},

		_getLocalImage: function() {
			let _this = this
			return new Promise((resolve, reject)=> {
				wx.chooseImage({
					count: 1, // 默认9
					sizeType: ['original', 'compressed'], // 可以指定是原图还是压缩图，默认二者都有
					sourceType: ['album', 'camera'], // 可以指定来源是相册还是相机，默认二者都有
					success: function (res) {
						var localIds = res.localIds; // 返回选定照片的本地ID列表，localId可以作为img标签的src属性显示图片
						let targetImgId = localIds[0]
						console.log('wkwebview is' +  !!window.__wxjs_is_wkwebview)
						wx.getLocalImgData({
							localId: targetImgId,
							success: (res) => {
								let localData = res.localData // localData是图片的base64数据，可以用img标签显示
								if (localData.indexOf('data:image') != 0) {
									//判断是否有这样的头部 => 兼容安卓机型（安卓机不包含data头）
									localData = 'data:image/jpeg;base64,' +  localData
								}
								resolve(localData)
							},
							fail: err=> {
								reject(err)
							}
						})
					},
					fail: err=> {
						reject(err)
					}
				});
			})
		},

		addTextHandle:function () {
			// let HText = this.canvas.height + ',' + AVAILD_HEIGHT + ',' + POSTER_HEIGHT + ',' + window.scrollY;
			let text = new fabric.Text(
				'点我修改文字',
				{ 
					left: 180 * SCALE_RATIO + MARGIN_LEFT, 
					top: 320 * SCALE_RATIO + MARGIN_TOP,
					fontFamily: 'PingFang SC', //'Comic Sans' ,
					fontSize: 20 * SCALE_RATIO,
					fill: '#fff',
					originX: 'center',
					padding: 10,
				})
			text.on('selected', this.selectedHandle)
			text.set('selectable', true)
			this.canvas.add(text)
			text.bringToFront();
		},

		deleteTextHandle: function() {
			let cObj = this.canvas.getActiveObject()
			this.canvas.remove(cObj)
			this.done()
		},

		fontChangeHandle: function() {
			let cObj = this.canvas.getActiveObject()
			if(cObj.type != 'text') {
				return
			}
			this.showFontChooseSheet = true
			this.loadFonts(cObj.fontFamily)
		},
		loadFonts: function(fm) {
			console.log(fm)
			if(fm == 'PingFang SC') {
				fm = '默认'
			}
			this.fontList.forEach(item => {
				let {name, loading} = item
				if(loading) {
					let font = new FontFaceObserver(name, {
						weight: 400
					});
					font.load().then(function () {
						console.log('Font is available, ' + name);
						item.loading = false
					}, function (e) {
						console.log('Font is not available, ' + name);
					});
				}
				item.chosen = fm == item.name
			})	
		},
		fontSelectHandle: function(font) {
			if(!font.chosen) {
				this.fontList.forEach(item=> {
					item.chosen = item.name == font.name
				})
				this.canvas.getActiveObject().set('fontFamily', font.name)
				this.canvas.requestRenderAll()
			}
			this.showFontChooseSheet = false
		},

	}
}
