import * as POSTER1 from './1.js';
import * as POSTER2 from './2.js';
import * as POSTER3 from './3.js';
import * as POSTER4 from './4.js';
import * as POSTER5 from './5.js';

const array = [
	[],
	POSTER1,
	POSTER2,
	POSTER3,
	POSTER4,
	POSTER5,
]
export default array